import React, {FC} from 'react';
import {IGenreItemWithChange} from "../../GenreItemPage";

const GenreItemInfoRight : FC<IGenreItemWithChange> = () => {
    return (
        <>
        </>
    );
};

export default GenreItemInfoRight;