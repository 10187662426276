import React, {FC} from 'react';
import {IGenreAdd} from "../../GenreAddPage";

const GenreAddInfoRight : FC<IGenreAdd> = () => {
    return (
        <>
        </>
    );
};

export default GenreAddInfoRight;