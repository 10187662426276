import React from 'react';
import "./Messages.scss"

const ErrorMessage = () => {
    return (
        <div className="ErrorMessage">
            Error
        </div>
    );
};

export default ErrorMessage;