import React from 'react';
import "./Messages.scss"

const SuccessMessage = () => {
    return (
        <div className="SuccessMessage">
            Success
        </div>
    );
};

export default SuccessMessage;